/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
:root {
  --accent: #e2af26;
}
$white: #fff;
$black: #161616;
$primary: #fefefe;
$secondary: rgba($primary, 0.7);
// $ternary: #999696;
$ternary: #b8adad;
$border: #333333;
// $gray: #181818;
$gray: #777777;
$accent: var(--accent);
