.cs-card.cs-style1 {
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 0px 30px #272424;
  display: block;

  &:hover {
    filter: drop-shadow(0px 0px 30px rgba(226, 175, 38, 0.5));
  }

  img {
    width: 100%;
    border-radius: inherit;
  }

  .cs-card_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: #000000;
    opacity: 0.3;
  }

  .cs-card_info {
    position: absolute;
    padding: 45px 25px 0;
    top: 0;
    left: 0;

    span {
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      // margin-bottom: 10px;
    }
  }

  .cs-card_title {
    font-size: 22px;
    margin: 0;
    line-height: 1.5em;
  }
}


@media screen and (max-width: 1400px) {
  .cs-card.cs-style1 .cs-card_title {
    font-size: 20px;
  }

  .cs-card.cs-style1 .cs-card_info {
    padding: 40px 18px 0;
  }
}

@media screen and (max-width: 1199px) {
  .cs-card.cs-style1 .cs-card_title {
    font-size: 24px;
  }

  .cs-card.cs-style1 .cs-card_info {
    padding: 40px 15px;
    // height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // width: 100%;
  }

}

@media screen and (max-width: 540px) {
  .cs-card.cs-style1 .cs-card_title {
    font-size: 12px;
  }

  .cs-card.cs-style1 .cs-card_info {
    padding: 20px 8px;
  }

}

.mainServicesHeading {
  font-size: 18px;
  font-weight: 100;
  line-height: 30px;
  text-align: center;
  color: #ffff;
}

.mainHeading {
  font-size: 35px;
  font-weight: 700;
  text-align: left;
  color: #ffff;
}

.timelineheading {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  color: #E2AF26;
}

.timelineContent {
  font-size: 16px;
  color: #ffff;
  font-weight: 400;
  text-align: left;
}

.timeline-card.cs-style1 {
  position: relative;
  border-radius: 15px;
  // box-shadow: 0px 0px 30px #272424;
  display: block;

  .timeline-card-image {
    height: 350px;
    width: 350px;
    border-radius: 50%;
    border: 2px solid #E2AF26;
    margin-left: 18%;
  }

  .timeline-card-image-left {
    height: 350px;
    width: 350px;
    border-radius: 50%;
    border: 2px solid #E2AF26;
    margin-right: 18%;
  }

  .timeline-card_info {
    position: absolute;
    padding: 45px 25px 0;
    top: 0;
    left: 0;

  }

  .timeline-card_title {
    font-size: 22px;
    margin: 0;
    line-height: 1.5em;
  }

  .sideIcon0 {
    position: absolute;
    height: 54px;
    width: 100px;
    left: -3.5%;
    top: 40%;
  }

  .sideIcon {
    position: absolute;
    height: 54px;
    width: 100px;
    // border-radius: 0px 89px 89px 0px;
    // background: #E2AF26;
    left: -3%;
    top: 40%;
  }

  .sideIconLeft {
    position: absolute;
    height: 54px;
    width: 100px;
    right: -3%;
    top: 40%;
  }
}

.servicesTimeLine {
  width: 100%;
}
.mainExpertiseBg{
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0px;
    top: -150px;
    height: 151px;
    width: 130px;
    background: #e2af26;
    opacity: 0.8;
    filter: blur(120px);
  }
}
@media screen and (max-width: 1400px) {
  .timeline-card.cs-style1 .timeline-card_title {
    font-size: 20px;
  }

  .cs-card.cs-style1 .cs-card_info {
    padding: 40px 18px 0;
  }
}

@media screen and (max-width: 1198px) {
  .timeline-card.cs-style1 {
    position: relative;
    border-radius: 15px;
    display: block;

    .timeline-card-image {
      height: 250px;
      width: 250px;
      margin-left: 22%;
    }

    .timeline-card-image-left {
      height: 250px;
      width: 250px;
      margin-right: 22%;
    }

    .sideIcon0 {
      position: absolute;
      height: 54px;
      width: 100px;
      left: -5.5%;
      top: 40%;
    }

    .sideIcon {
      position: absolute;
      height: 54px;
      width: 100px;
      // border-radius: 0px 89px 89px 0px;
      // background: #E2AF26;
      left: -4%;
      top: 40%;
    }

    .sideIconLeft {
      position: absolute;
      height: 54px;
      width: 100px;
      right: -4%;
      top: 40%;
    }
  }
}

@media screen and (max-width: 990px) {
  .mainHeading {
    font-size: 20px;
  }

  .timelineheading {
    font-size: 18px;
  }

  .timelineContent {
    font-size: 13px;
  }

  .timeline-card.cs-style1 {
    position: relative;
    border-radius: 15px;
    display: block;

    .timeline-card-image {
      height: 200px;
      width: 200px;
      margin-left: 22%;
    }

    .timeline-card-image-left {
      height: 200px;
      width: 200px;
      margin-right: 22%;
    }

    .sideIcon0 {
      position: absolute;
      height: 50px;
      width: 70px;
      left: -6.5%;
      top: 40%;
    }

    .sideIcon {
      position: absolute;
      height: 50px;
      width: 70px;
      // border-radius: 0px 89px 89px 0px;
      // background: #E2AF26;
      left: -4%;
      top: 40%;
    }

    .sideIconLeft {
      position: absolute;
      height: 50px;
      width: 70px;
      right: -4%;
      top: 40%;
    }
  }
}

@media screen and (max-width: 670px) {
  .mainHeading {
    font-size: 18px;
  }

  .timelineheading {
    font-size: 15px;
  }

  .timelineContent {
    font-size: 11px;
  }

  .timeline-card.cs-style1 {

    .timeline-card-image {
      height: 150px;
      width: 150px;
      margin-left: 24%;
    }

    .timeline-card-image-left {
      height: 150px;
      width: 150px;
      margin-right: 24%;
    }

    .sideIcon0 {
      position: absolute;
      height: 50px;
      width: 70px;
      left: -7%;
    }

    .sideIcon {
      position: absolute;
      height: 50px;
      width: 70px;
      // border-radius: 0px 89px 89px 0px;
      // background: #E2AF26;
      left: -5.5%;
    }

    .sideIconLeft {
      position: absolute;
      height: 50px;
      width: 70px;
      right: -5.5%;
    }
  }
}

@media screen and (max-width: 555px) {
  .timeline-card.cs-style1 {
    .sideIcon0 {
      display: none;
    }

    .sideIcon {
      display: none;
    }

    .sideIconLeft {
      display: none;
    }
  }
}

@media screen and (max-width: 430px) {
  .mainHeading {
    font-size: 15px;
  }

  .timelineheading {
    font-size: 12px;
  }

  .timelineContent {
    font-size: 8px;
  }

  .timeline-card.cs-style1 {

    .timeline-card-image {
      height: 100px;
      width: 100px;
      margin-left: 20%;
    }

    .timeline-card-image-left {
      height: 100px;
      width: 100px;
      margin-right: 35%;
    }
  }
}

@media screen and (max-width: 375px) {
  .mainHeading {
    font-size: 13px;
  }

  .timelineheading {
    font-size: 10px;
  }

  .timelineContent {
    font-size: 8px;
  }

  .timeline-card.cs-style1 {
    .timeline-card-image {
      height: 100px;
      width: 100px;
      margin-left: 10%;
    }

    .timeline-card-image-left {
      height: 100px;
      width: 100px;
      margin-right: 10%;
    }
  }
}


.mainBox {
  position: relative;
  border-radius: 17px;
  background: #505050 !important;
  text-align: center;
  height: 270px;
}

.cardIconBox {
  position: absolute;
  top: -15%;
  left: 40%;
}

.expertiseHeading {
  font-size: 32px;
  font-weight: 700;
  color: #E2AF26;

}


@media screen and (max-width: 1200px) {
  .mainBox {
    .cardTitle {
      font-size: 18px;
    }

    .cardText {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 766px) {
  .mainBox {
    .cardIconBox {
      left: 44%;
    }

    .cardTitle {
      font-size: 22px;
    }

    .cardText {
      font-size: 15px;
    }
  }
}