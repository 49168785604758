// Variables
$primary-color: #e2af26;
$text-color: #6c757d;
$heading-color: #333;

// Breakpoints
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;

// Container and Grid


.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    align-items: center;

    @media (min-width: $breakpoint-lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    .col-span-2 {
        grid-column: span 2;
    }
}

.job-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    align-items: center;
    padding: 16px 0;
    border-top: 2px solid #ccc;
    position: relative;

    @media (min-width: $breakpoint-md) {
        grid-template-columns: repeat(8, 1fr);
    }

    @media (min-width: $breakpoint-lg) {
        grid-template-columns: repeat(12, 1fr);
    }

    &.job-section-border {
        border-bottom: 2px solid #ccc;
    }

    .job-details, .job-description, .apply-button {
        grid-column: span 4;

        @media (min-width: $breakpoint-md) {
            grid-column: span 8;
        }

        @media (min-width: $breakpoint-lg) {
            grid-column: span 4;
        }
    }
}

// Text Styles
.heading {
    font-family: 'Rajdhani', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
}

.primary-color {
    color: $primary-color;
}

.paragraph {
    font-family: 'Nunito', sans-serif;
    color: $text-color;
}

.job-title {
    font-family: 'Rajdhani', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 5px;
}

.job-type, .location {
    font-family: 'Nunito', sans-serif;
    // color: $text-color;
    font-weight: 300;
}

.job-type {
    background-color: $primary-color;
    color: #fff;
    padding: 5px 26px;
    border-radius: 9999px;
}

.requirements-title {
    font-family: 'Rajdhani', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: $heading-color;
    margin-bottom: 7px;
    color: white;
}

// Button Styles
.button {
    font-family: 'Nunito', sans-serif;
    color: $heading-color;
    padding: 14px 60px;
    border: 1px solid transparent;
    border-radius: 9999px;
    transition: all 0.5s;

    &:hover {
        border-color: $primary-color;
    }

    @media (max-width: $breakpoint-md) {
        padding: 12px 40px;
    }
}
