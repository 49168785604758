@import '../../scss/default/color_variable';
.cs-cta.cs-style1 {
  padding: 90px 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 30px #1d1b1b;
  .cs-cta_in {
    position: relative;
    z-index: 2;
  }
  .cs-cta_title {
    font-weight: 600;
    font-size: 50px;
  }
  &.cs-type_1 {
    border-radius: 0;
    @media (min-width: 992px) {
      padding: 140px 30px 145px;
    }
  }
}
.cs-cta.cs_style_2 {
  height: 700px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  @media (max-width: 991px) {
    height: 420px;
  }
  .cs-cta_btn {
    margin: 0;
    font-size: 50px;
    font-style: italic;
    font-weight: 600;
    line-height: 1.3em;
    display: inline-block;
    svg {
      margin-left: 18px;
    }
    @media (max-width: 991px) {
      font-size: 36px;
      svg {
        width: 22px;
        height: 22px;
      }
    }

    span {
      display: inline-block;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 5px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: currentColor;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .cs-cta.cs-style1 .cs-cta_title {
    font-size: 42px;
  }
}
@media screen and (max-width: 991px) {
  .cs-cta.cs-style1 {
    padding: 70px 30px;
    border-radius: 15px;
    .cs-cta_title {
      font-size: 36px;
      br {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .cs-cta.cs-style1 {
    br {
      display: none;
    }
  }
}
@media screen and (max-width: 575px) {
  .cs-cta.cs-style1 {
    padding: 70px 20px;
    .cs-cta_title {
      font-size: 28px;
    }
  }
}
